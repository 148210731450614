// https://stackoverflow.com/a/68582722/3670829
// TODO: merge with this code https://github.com/ghashi/br-vigilantes-do-sono-monorepo/pull/442#discussion_r854654108
export const toBoolean = (str: 'true' | 'false' | string): boolean => {
  try {
    return JSON.parse(str) as boolean;
  } catch (error) {
    console.error('ERROR: to-boolean.ts ~ line 8 ~ toBoolean ~ error', str, error);
    return null;
  }
};
