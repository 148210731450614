import { Col, LargeSeparator, Row } from '@web/atomic';
import * as React from 'react';

// Component created to supply the necessity of a single row Large Separator, this way the margin do not collapses or ignore the background
const LargeSeparatorRow: React.FunctionComponent = () => {
  return (
    <Row>
      <Col xs>
        <LargeSeparator />
      </Col>
    </Row>
  );
};

export default LargeSeparatorRow;
